import { publicRuntimeConfig } from 'config';
import type {
	ApiJulaModelsCartCartResponseModel,
	ApiJulaModelsCartJulaVariantModel,
	ApiJulaModelsCartSummaryModel,
} from 'models/api';
import { assertUnreachable } from 'utils/helpers';

import type {
	AddRemoveCartItemGTMProduct,
	GTMCurrency,
	GTMEvent,
	GTMItemListId,
	GTMItemListName,
	ItemWithPrice,
	ListItemGTMProduct,
	Purchase,
	ViewItemGTMProduct,
	WishListItemGTMProduct,
} from './GTMEvents';
import type {
	GTMHelperInput,
	GTMItemProduct,
	GTMListProduct,
	GTMPriceProduct,
} from './GTMHelperInputs';

function getViewItemProductData(product: GTMItemProduct): ViewItemGTMProduct {
	return {
		price: product.displayPrice?.priceIncVat?.value ?? 0,
		quantity: 1,
		item_id: product.id,
	};
}

function getListItemProductData(
	product: GTMListProduct,
	index: number,
	itemListId: GTMItemListId,
	itemListName: GTMItemListName,
): ListItemGTMProduct {
	return {
		index,
		item_id: product.id,
		item_list_id: itemListId,
		item_list_name: itemListName,
	};
}

function getPrice(product: GTMPriceProduct): number {
	let price = 0;
	if ('listPrice' in product) {
		price = product.listPrice?.priceIncVat?.value ?? 0;
	}
	if ('displayPrice' in product) {
		price = product.displayPrice?.priceIncVat?.value ?? 0;
	}
	if ('price' in product) {
		price = product.price?.priceIncVat?.value ?? 0;
	}
	return price;
}

function getWishlistTotalValue(
	product: GTMPriceProduct,
	quantity: number,
): number {
	return getPrice(product) * quantity;
}

function getWishlistItemProductData(
	product: GTMPriceProduct,
	quantity: number,
	itemListId?: GTMItemListId,
	itemListName?: GTMItemListName,
): WishListItemGTMProduct {
	const listParams =
		itemListId && itemListName
			? { item_list_id: itemListId, item_list_name: itemListName }
			: {};
	return {
		...listParams,
		item_id: product.id!,
		price: getPrice(product),
		quantity,
	};
}

function getCurrency(): GTMCurrency {
	switch (publicRuntimeConfig?.NEXT_PUBLIC_JULA_MARKET_CODE) {
		case 'SE':
			return 'SEK';

		case 'NO':
			return 'NOK';

		case 'PL':
			return 'PLN';

		case 'FI':
		case 'AT':
		case 'DE':
			return 'EUR';

		default:
			return 'unknown market';
	}
}

function getAddRemoveItemProductData(
	product: GTMPriceProduct,
	quantity: number,
	itemListId?: GTMItemListId,
	itemListName?: GTMItemListName,
): AddRemoveCartItemGTMProduct {
	const listParams =
		itemListId && itemListName
			? { item_list_id: itemListId, item_list_name: itemListName }
			: {};
	return {
		...listParams,
		item_id: product.id!,
		price: getPrice(product),
		quantity,
		currency: getCurrency(),
	};
}

function getItemsWithIdPriceQty(
	products: ApiJulaModelsCartJulaVariantModel[],
): ItemWithPrice[] {
	return products.map((product) => ({
		item_id: product.id,
		price: product.price.priceIncVat?.value ?? 0,
		quantity: product.qty,
	}));
}

function getProductDataList(
	products: GTMListProduct[],
	pageSize: number,
	itemListId: GTMItemListId,
	itemListName: GTMItemListName,
): ListItemGTMProduct[] {
	let pages = Math.trunc(products.length / pageSize);
	pages += products.length % pageSize > 0 ? 1 : 0;
	const startIndex = pages > 1 ? (pages - 1) * pageSize : 0;

	const items: ListItemGTMProduct[] = [];
	for (let i = startIndex; i < products.length; i++) {
		items.push(
			getListItemProductData(products[i]!, i + 1, itemListId, itemListName),
		);
	}

	return items;
}

function getSumValue(
	cart: ApiJulaModelsCartCartResponseModel,
	sumType: ApiJulaModelsCartSummaryModel['sumType'],
): number {
	const summary = cart.summaries.find((sum) => sum.sumType === sumType);
	return summary?.value ?? 0;
}

function getPurchaseData(cart: ApiJulaModelsCartCartResponseModel): Purchase {
	return {
		transaction_id: cart.orderNumber!,
		cart_id: cart.id,
		value: getSumValue(cart, 'Total'),
		tax: getSumValue(cart, 'Vat'),
		shipping: getSumValue(cart, 'Freight'),
		currency: getCurrency(),
		items: cart.variants.map((variant) => ({
			item_id: variant.id,
			price: variant.price.priceIncVat?.value ?? 0,
			quantity: variant.qty,
		})),
	};
}

// Want explicit case handling with an unreachable default.
// eslint-disable-next-line consistent-return
export function formatGTMData(event: GTMHelperInput): GTMEvent {
	const { type } = event;
	switch (type) {
		case 'view_item_list':
			return {
				event: type,
				ecommerce: {
					items: getProductDataList(
						event.payload.products,
						event.payload.pageSize,
						event.payload.itemListId,
						event.payload.itemListName,
					),
				},
			};

		case 'select_item':
			return {
				event: type,
				ecommerce: {
					items: [
						getListItemProductData(
							event.payload.product,
							event.payload.productIndex + 1,
							event.payload.itemListId,
							event.payload.itemListName,
						),
					],
				},
			};

		case 'view_item':
			return {
				event: type,
				ecommerce: {
					currency: getCurrency(),
					items: [getViewItemProductData(event.payload.product)],
					category: event.payload.category,
				},
			};

		case 'purchase':
			return {
				event: type,
				ecommerce: getPurchaseData(event.payload.cart),
			};

		case 'add_to_cart':
		case 'remove_from_cart':
			return {
				event: type,
				ecommerce: {
					items: [
						getAddRemoveItemProductData(
							event.payload.product,
							event.payload.quantity,
							'itemListId' in event.payload
								? event.payload.itemListId
								: undefined,
							'itemListName' in event.payload
								? event.payload.itemListName
								: undefined,
						),
					],
					cart_id: event.payload.cartId,
				},
			};

		case 'add_multiple_to_cart':
			return {
				event: 'add_to_cart',
				ecommerce: {
					items: event.payload.productsData.map(({ product, quantity }) =>
						getAddRemoveItemProductData(product, quantity),
					),
					cart_id: event.payload.cartId,
				},
			};

		case 'add_to_wishlist':
			return {
				event: type,
				ecommerce: {
					value: getWishlistTotalValue(
						event.payload.product,
						event.payload.quantity,
					),
					currency: getCurrency(),
					items: [
						getWishlistItemProductData(
							event.payload.product,
							event.payload.quantity,
							event.payload.itemListId,
							event.payload.itemListName,
						),
					],
				},
			};

		case 'view_cart':
			return {
				event: type,
				ecommerce: {
					items: getItemsWithIdPriceQty(event.payload.products),
					cart_id: event.payload.cartId,
				},
			};

		case 'begin_checkout':
			return {
				event: type,
				ecommerce: {
					items: getItemsWithIdPriceQty(event.payload.products),
				},
			};

		case 'add_payment_info':
			return {
				event: type,
				ecommerce: {
					payment_type: event.payload.paymentType,
					items: getItemsWithIdPriceQty(event.payload.products),
				},
			};

		case 'add_shipping_info':
			return {
				event: type,
				ecommerce: {
					shipping_tier: event.payload.shippingTier,
					items: getItemsWithIdPriceQty(event.payload.products),
				},
			};

		case 'login':
		case 'login_renewal':
			return {
				event: type,
				voyado_id: event.payload.voyadoId,
			};

		case 'order_history_end':
			return {
				event: type,
				date: event.payload.date,
			};

		case 'open_credit_simulation':
		case 'logout':
		case 'search_term_brand_view':
		case 'search_term_category_view':
		case 'search_term_brand_select':
		case 'search_term_category_select':
		case 'select_store_open':
			return {
				event: type,
			};

		case 'select_store_choice':
			return {
				event: type,
				payload: {
					store_name: event.payload.storeName,
					store_stock: event.payload.storeStock,
				},
			};

		case 'view_promotion':
		case 'select_promotion':
			return {
				event: type,
				promotion_id: event.payload.id,
				promotion_name: event.payload.name,
			};

		case 'user_information_validation_popover_dismissed':
			return {
				event: 'prompt_user_details_dismiss',
			};

		case 'user_information_validation_popover_displayed':
			return {
				event: 'prompt_user_details_display',
			};

		case 'user_information_validation_popover_go_to_verification':
			return {
				event: 'prompt_user_details_update',
			};

		default:
			assertUnreachable(type, 'GTMEvent.type');
	}
}
